import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, fontSizes, palette, spacing}) => ({
  backLink: {
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: fontSizes.description,
    fontFamily: 'CamptonSemiBold',
    textDecoration: 'none',
    textTransform: 'none',
    [breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
  positionRelative: {
    marginTop: spacing(4),
  },
  positionAbsolute: {
    width: 'max-content',
    position: 'absolute',
    top: spacing(3.5),
  },
  isVisibleOnMobile: {
    [breakpoints.down('sm')]: {
      paddingTop: spacing(5),
      visibility: 'visible',
    },
  },
  isSecondary: {
    color: palette.secondary.main,
  },
}))

export default useStyles
