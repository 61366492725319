import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  wrapper: {
    display: 'flex',
    marginTop: spacing(10),
    marginBottom: spacing(10),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: spacing(5),
    },
  },
  textBlock: {
    display: 'block',
    '& > h1': {
      marginBottom: spacing(5),
    },
    [breakpoints.up('md')]: {
      marginRight: spacing(17.5),
    },
  },
  iconWrap: {
    width: '50%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      width: '100%',
      marginTop: spacing(4),
      '& img': {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
      },
    },
  },
  icon: {
    maxHeight: 300,
    maxWidth: 300,
  },
}))

export default useStyles
