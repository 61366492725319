import * as R from 'ramda'
import {arrayOf, bool, objectOf, string} from 'prop-types'
import {Button} from '@material-ui/core'
import {navigate} from 'gatsby'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const HashTags = ({tags, hasColormapping, isDisabled}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  return (
    <span className={classes.tagsContainer}>
      {mapIndexed((tag, mapIndex) => {
        const moduloIndex = mapIndex % 3
        const renderTagName = tagName =>
          tagName.includes('#') ? tagName : `#${tagName}`

        const renderTagNameForPlaybook = tagName =>
          !tagName.includes('#') ? tagName : tagName.replace(/#/g, '')

        return (
          <Button
            style={{pointerEvents: isDisabled && 'none'}}
            className={classNames(
              classes.tag,
              hasColormapping && {
                [classes.isPrimary]: moduloIndex === 0,
                [classes.isSecondary]: moduloIndex === 1,
                [classes.isTertiary]: moduloIndex === 2,
              },
            )}
            role="button"
            tabIndex={0}
            size="small"
            onClick={() =>
              navigate(
                `../../playbook#/tag-${renderTagNameForPlaybook(
                  tag.name.name || tag.name || tag,
                )}`,
              )
            }
          >
            {renderTagName(tag.name.name || tag.name || tag)}
          </Button>
        )
      }, tags)}
    </span>
  )
}

HashTags.propTypes = {
  hasColormapping: bool,
  isDisabled: bool,
  tags: arrayOf(objectOf({name: string})),
}
HashTags.defaultProps = {
  hasColormapping: false,
  isDisabled: false,
  tags: [],
}

export default HashTags
