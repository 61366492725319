import * as R from 'ramda'
import {Link} from 'gatsby'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import React from 'react'

import {articleInsightPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'
import Section from 'components/UI/Section'

import useStyles from './styles'

const Article = ({
  content,
  industries,
  type,
  services,
  hasPaddingBottom = true,
  richTextDocuments,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const industryTags = industries && R.slice(0, 3, industries)
  // const servicesTags = R.slice(0, 3, services)

  const renderIndustryTags = industryTags
    ? () =>
        mapIndexed(
          (tag, index) =>
            tag && (tag.name || tag.name.name) ? (
              <Link to={`../#/${tag.name || tag.name.name}`}>
                <Button
                  className={classNames(classes.tag, classes.industrieTags)}
                  size="small"
                  key={index}
                >
                  #{tag.name || tag.name.name}
                </Button>
              </Link>
            ) : null,
          industryTags,
        )
    : null

  const renderTypeTags = tagType => (
    <Link to={`../#/${tagType.name}`}>
      <Button
        className={classNames(classes.tag, classes.typeTags)}
        size="small"
      >
        #{tagType.name}
      </Button>
    </Link>
  )

  const renderServicesTags = services
    ? () =>
        mapIndexed(
          (tag, index) =>
            tag && (tag.name || tag.name.name) ? (
              <Link to={`../#/${tag.name || tag.name.name}`}>
                <Button
                  className={classNames(classes.tag, classes.serviceTags)}
                  size="small"
                  key={index}
                >
                  #{tag.name || tag.name.name}
                </Button>
              </Link>
            ) : null,
          services,
        )
    : null

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={hasPaddingBottom}>
      <div className={classes.container}>
        <div className={classes.articleContainer}>
          <RawText
            text={content}
            richTextDocuments={richTextDocuments}
            isJustified
          />
        </div>
      </div>
      <div className={classes.tagsContainer}>
        {industries ? renderIndustryTags(industries) : ''}
        {type ? renderTypeTags(type) : ''}
        {services ? renderServicesTags(services) : ''}
      </div>
    </Section>
  )
}

Article.propTypes = articleInsightPropTypes.isRequired

export default Article
