import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({palette, fontSizes, breakpoints, layout, spacing}) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    articleContainer: {
      width: '85%',
      marginBottom: spacing(7),
      [breakpoints.down('sm')]: {
        marginTop: spacing(12),
        marginBottom: 0,
        width: '100%',
      },
    },
    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& Button': {
        margin: 3,
      },
    },
    tag: {
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
    },
    serviceTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
    },
    industrieTags: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
    },
    typeTags: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },
  }),
)

export default useStyles
